import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as MuiLink, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  inverted: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  underline: {
    textDecoration: 'underline',
  },
  light: {
    fontWeight: theme.typography.weight.thin,
    color: theme.palette.grayScale.g300,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  }
}));

const Link = ({ to, className, children, inverted, underline, light, ...props }) => {
  const classes = useStyles();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <GatsbyLink innerRef={ref} to={to} {...linkProps} />
      )),
    [to],
  );
  return (
    to ?
      (
        <MuiLink
          className={
            clsx(className, {
              [classes.inverted]: inverted,
              [classes.underline]: underline,
              [classes.light]: light,
            })}
          component={CustomLink}
          {...props}
        >
          {children}
        </MuiLink>
      ) :
      (
        <MuiLink
          className={
            clsx(className, {
              [classes.inverted]: inverted,
              [classes.underline]: underline,
              [classes.light]: light,
            })}
          {...props}
        >
          {children}
        </MuiLink>
      )
  );
};

export default Link;

