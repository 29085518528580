const googleTagId = process.env.GATSBY_CARMUV_ANALYTICS_ID;
const scriptUrlPart = 'https://www.googletagmanager.com/gtm.js';

const googleTagHeadScript =
    `
    const isScriptAlreadyLoaded = () => {
    return Boolean(document.querySelector('script[src^="${scriptUrlPart}"]'));
    }
    if (!isScriptAlreadyLoaded()) {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagId}')
    }

`;

const googleTagNoScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;

export { googleTagHeadScript, googleTagNoScript }