
import React from 'react';
import blackLogo from '../../assets/images/carmuv-logo-black.png';
import whiteLogo from '../../assets/images/carmuv-logo-white.png';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const LOGO_ALT = 'logo';

const useStyles = makeStyles((theme) => ({
  image: {
    height: '80px',
    margin: 0,
    maxWidth: 'none',
  }
}));

const Logo = ({ white, className }) => {
  const classes = useStyles();
  let logo = white ? whiteLogo : blackLogo;

  return (
    <img src={logo} className={clsx(classes.image, className)} alt={LOGO_ALT} />
  );
};


export default Logo;
