import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const usesStyle = makeStyles((theme) => ({
  // Size
  smallest:{
    fontSize: theme.typography.size.smallest
  },
  smaller:{
    fontSize: theme.typography.size.smaller
  },
  little:{
    fontSize: theme.typography.size.little
  },
  small:{
    fontSize: theme.typography.size.small
  },
  medium:{
    fontSize: theme.typography.size.medium
  },
  large:{
    fontSize: theme.typography.size.large
  },
  larger:{
    fontSize: theme.typography.size.larger
  },
  xLarge:{
    fontSize: theme.typography.size.xLarge
  },
  xxLarge:{
    fontSize: theme.typography.size.xxLarge
  },
  // Weight
  thin: {
    fontWeight: theme.typography.weight.thin,
  },
  normal: {
    fontWeight: theme.typography.weight.normal,
  },
  semibold: {
    fontWeight: theme.typography.weight.semibold,
  },
  semibolder:{
    fontWeight: theme.typography.weight.semibolder,
  },
  bold: {
    fontWeight: theme.typography.weight.bold,
  },
  // Style
  cursive: {
    fontStyle: 'italic',
  },
  shadow: {
    textShadow: theme.typography.shadow.main,
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  // Color
  primary: {
    color: theme.palette.secondary.main,
  },
  secondary: {
    color: theme.palette.primary.main,
  },
  g300: {
    color: theme.palette.grayScale.g300,
  },
  white: {
    color: theme.palette.grayScale.g0,
  },
  light: {
    color: theme.palette.grayScale.g400,
  },
  warning: {
    color: theme.palette.error.main,
  },
  g500: {
    color: theme.palette.grayScale.g500
  },
  g600: {
    color: theme.palette.grayScale.g600
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  }
}));

const Text = ({
  children,
  className,
  smallest,
  smaller,
  little,
  small,
  medium,
  large,
  larger,
  xLarge,
  xxLarge,
  thin,
  normal,
  bold,
  semibold,
  semibolder,
  cursive,
  shadow,
  upperCase,
  capitalize,
  primary,
  secondary,
  g300,
  white,
  light,
  warning,
  left,
  center,
  right,
  g500,
  g600,
  ...otherProps }) => {
  const classes = usesStyle();

  return (<span
    className={
      clsx(classes.baseStyle, {
        // Size
        [classes.smallest]: smallest ,
        [classes.smaller]: smaller ,
        [classes.little]: little ,
        [classes.small]: small ,
        [classes.medium]: medium ,
        [classes.large]: large ,
        [classes.larger]: larger ,
        [classes.xLarge]: xLarge,
        [classes.xxLarge]: xxLarge,
        // Weight
        [classes.thin]: thin,
        [classes.normal]: normal,
        [classes.semibold]: semibold,
        [classes.semibolder]: semibolder,
        [classes.bold]: bold,
        // Style
        [classes.cursive]: cursive,
        [classes.shadow]: shadow,
        [classes.upperCase]: upperCase,
        [classes.capitalize]: capitalize,
        // Color
        [classes.primary]: primary,
        [classes.secondary]: secondary,
        [classes.g300]: g300,
        [classes.white]: white,
        [classes.light]: light,
        [classes.warning]: warning,
        [classes.g500]: g500,
        [classes.g600]: g600,
        //Alignment
        [classes.left]: left,
        [classes.center]: center,
        [classes.right]: right,
      },
      className)
    }
    {...otherProps}
  >
    {children}
  </span>);
};

export default Text;
